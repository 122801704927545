@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');
$primary-color: #0061FF;
$primary-color-light: #92bcff;
$primary-color-dark: #002a6d;
$dark-color: #3d3d3d;
$secondary-color: #383838;
$accept-color: #0061FF;
$cancel-color: #66001f;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
	font-family: Raleway, sans-serif;
}

.bg_1 {
	background: url('/static/images/bg.svg');	
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.container {
	display: flex;
	justify-content: center;
	min-height: 100vh;
    max-width: 100%;
}

body{
	padding-bottom: 30px;
}

.screen {		
	background: linear-gradient(90deg, $primary-color-light, $primary-color);		
	position: relative;	
	height: 600px;
	width: 360px;	
    max-width: 100%;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.432);
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 520px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: $primary-color-dark;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, $primary-color-dark, $primary-color);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: $primary-color-light;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}

.login {
	width: 320px;
	padding: 30px;
    max-width: 100%;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}

.login__icon {
	position: absolute;
	top: 30px;
	color: $primary-color-dark;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: $primary-color-dark;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 10px 20px;
	border-radius: 26px;
	border: 1px solid $primary-color-light;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	color: $primary-color-dark;
	cursor: pointer;
	transition: .5s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: $primary-color-dark;
	outline: none;
    transition: 0.5s;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #7875B5;
}

.social-login {	
	position: absolute;
	height: 140px;
	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #fff;
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
	transform: scale(1.5);	
}



.form_login {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 27px 12px 30px #00000029;
	border: 1px solid #707070;
	opacity: 1;
	width: 537px;
	height: 324px;
	border-radius: 20px;
	max-width: 100%;
	padding: 50px 40px;
	margin: 20px 0;
	max-width: 90%;
  }

  .mx537px{
	max-width: 537px;
	width: 100%;
  }

  .btn_login {

	height: 50px;
	/* UI Properties */
	background: transparent linear-gradient(130deg, #4d8aea 0%, #225ebe 100%) 0%
	  0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;
	margin-top: 30px;
  }
  
  .input_login {
	/* UI Properties */
	width: 423px;
	height: 49px;
	/* UI Properties */
  
	border: 1px solid #adadad;
	border-radius: 8px;
	opacity: 1;
	max-width: 100%;
	padding: 10px;
	margin: 10px;
  }

  .input_email{
	background: none;
	border: none;
	border-bottom: 1px solid rgb(181, 181, 181);
	width: 100%;
	height: 25px;
	padding: 5px;
  }
