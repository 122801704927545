.navbar{
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    z-index: 999;
}

.menu{
    background: #000 ;
    z-index: 1;
    max-width: 50vw;
}

.dropdown{
    position: absolute;
    padding: 5px;
    z-index: 1;
    border-radius: 2px;
    background: rgb(244, 244, 244);
    min-width: 150px;
    right: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.201);
}

.p-inputnumber-buttons-stacked{
    max-width: 20px
  }

.p-inputnumber-input{
    width: 100%;
}

.options{
    padding: 5px;
    width: 22px;
    height: 22px;
    border-radius: 2px;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.options:hover{
    background: rgba(187, 187, 187, 0.372);
    transition: 0.5s;
}

.dropitem{
    margin: 5px;
    transition: 0.5s;
    padding: 5px 10px;
    border-radius: 3px;
}

.dropitem:hover{
   background: rgba(145, 145, 145, 0.236);
}

.click_action{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
}

.input{
    background: transparent;
    border: none;
}

.submenu{
    background: rgb(29, 29, 29) ;
    padding: 35px 20px;
    border-radius: 0 10px 10px 0;
    height: 100%;
    max-width: 50vw;
    display: none;
}

.submenu.active{
   display: block;
   min-width: 200px;
}

.content{
    padding: 15px;
    margin-left: 70px;
}

.field{
    padding: 0px 10px;
}

.m_25px{
    margin: 25px;
}
.m_15px{
    margin: 15px;
}

.m_10px{
    margin: 10px;
}

.green_1{
    background: #35E08D !important;
}

.tblack{
    color: black !important;
}

.gap_25{
    gap: 25px;
}

.gap_15{
    gap: 15px;
}

.gap_50{
    gap: 50px;
}

.option{
    width: 130px;
    height: 130px;
    border-radius: 10px;
    padding: 10px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.253);
    transition: 0.5s;
    animation-fill-mode: backwards !important;
    margin-top: 10px;
}

.pointier{
    cursor: pointer;
}

.pad5{
  padding: 5px;
}

.pad10{
    padding: 10px;
}

.pad20{
    padding: 20px;
}

.padlr20{
    padding: 0px 20px;
}


.mtop15{
    margin-top: 15px;
}

.mtop10{
    margin-top: 10px;
}

.mtop25{
    margin-top: 25px;
}


.mb15{
    margin-bottom: 15px;
}

.mb10{
    margin-bottom: 10px;
}

.mb25{
    margin-bottom: 25px;
}

.tabs{
    padding: 8px;
    border-radius: 3px;
    background: rgb(222, 222, 222);
}

.tab{
    margin: 5px 10px;
    padding: 7px 13px;
    border-radius: 3px;
    transition: 0.5s;
}

.tab:hover, .tab.active{
    background: rgb(56, 56, 56);
    color: #fff;
    transition: 0.5s;
}

.thead{
    color: rgb(0, 94, 255);
}


.editando{
    padding: 5px 10px;
    background: rgb(215, 235, 250);
    border-radius: 3px;
    color: rgb(25, 77, 119);
    font-weight: 900;
}



.aprobado{
    padding: 7px 10px;
    background: #35E190;
    border-radius: 3px;
    color: rgb(0, 0, 0);
}

.activo{
    padding: 7px 10px;
    background: #35E190;
    border-radius: 3px;
    color: rgb(0, 0, 0);
    width: 70px;
    text-align: center;
}

.enviado{
    padding: 7px 10px;
    background: #FEE555;
    border-radius: 3px;
    color: rgb(0, 0, 0);
}

.enviar{
    padding: 7px 10px;
    background: #55aafe;
    border-radius: 3px;
    color: rgb(0, 0, 0);
}

.rechazado{
    padding: 7px 10px;
    background: #ff9090;
    border-radius: 3px;
    color: rgb(0, 0, 0);
}

.inactivo{
    padding: 7px 10px;
    background: #ff9090;
    border-radius: 3px;
    color: rgb(0, 0, 0);
    text-align: center;
    width: 70px;
}

.column{
    max-width: 200px;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.column::-webkit-scrollbar {
    display: none;
  }

.pendiente{
    padding: 7px 10px;
    background: #FEE555;
    border-radius: 3px;
    color: rgb(0, 0, 0);
}

.clamp_1{
    -webkit-line-clamp:2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.none{
    display: none !important;
}

.option.active,.option:hover{
    transform: translateY(-10px);
    transition: 0.5s;
    animation-fill-mode: backwards;
}

li{
    list-style: none;
}

.item_menu{
    min-width: 40px;
    height: 40px;
    margin: 10px auto;
    border-radius: 5px;
    transition: 0.5s;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.name_menu{
    width: 0px;
    transition: 0.5s;
}

.name_item_menu{
   transition: 0.5s;
   margin: 5px;
}

.name_item_menu.active, .name_item_menu:hover{
    background: rgba(255, 255, 255, 0.12);
    transition: 0.5s;
}

.filtro{
    padding: 7px 20px;
    border: 1px solid rgb(214, 214, 214);
    background: rgb(222, 222, 222);
    border-radius: 4px;
    width: 100px;
    height: 30px;
}

.btn_2{
    padding: 7px 20px;
    border: 1px solid rgb(214, 214, 214);
    background: transparent;
    border-radius: 4px;
    text-align: center;
    margin: 5px;
    transition: 0.5s;
}

.btn_2.active{
    background: #35E08D;
    border: 1px solid #33d386;
}

.btn_2.danger.active{
    background: #ff9090;
    border: 1px solid #ff6f6f;
}

.danger{
    color: #ff9090;
}

.success{
    color: #32C781;
}

.btn_2:hover{
    background: #35E08D;
    border: 1px solid #33d386;
    transition: 0.5s;
}

.btn_2.danger:hover{
    background: #ff9090;
    border: 1px solid #ff6f6f;
    transition: 0.5s;
}

.filter-select{
    top: 100%;
    width: 200px;
    padding: 10px;
    left: 0;
    background: #efefef;
    box-shadow: 0px 0px 5px #00000042;
    margin: 10px 0;
    border-radius: 3px;
    z-index: 5;
}

.filter-clear{
    height: 30px;
    width: 30px;
    background: rgba(255, 219, 219, 0.617);
    border: 1px solid rgb(255, 169, 169);
    font-size: 20px;
    color: rgb(212, 82, 82);
    border-radius: 3px;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.5s;
}

.filter-clear:hover{
    background: rgba(255, 190, 190, 0.617);
    border: 1px solid rgb(255, 129, 129);
    transition: 0.5s;
    font-size: 22px;
}

.filter-op{
    margin: 5px 0;
    padding: 5px;
    border-radius: 3px;
    transition: 0.5s;
}

.filter-op:hover, .filter-op.active{
    background: rgb(0 78 255 / 40%);
    width: 100%;
    transition: 0.5s;
}

.filter-status{
    background: #104AC3;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    color: white;
}

.name_menu.active{
    width: 150px;
    transition: 0.5s;
    color: rgb(212 212 212);
}

.white{
    color: #fff;
}

.f_dark{
    color: rgb(184, 184, 184);
}

.btn{
    padding: 10px 20px;
    background: rgba(110, 110, 110, 0.527);
    border: none;
    color: white;
    border-radius: 3px;
}

.btn_menu.active, .btn_menu:hover{
    padding: 10px 20px;
    background: #35E190;
    border: 1px solid #32C781;
    color: black;
    border-radius: 3px;
    transition: 0.5s;
}

.btn_menu{
    padding: 10px 20px;
    background: rgba(110, 110, 110, 0.527);
    border: none;
    color: white;
    border-radius: 3px;
    transition: 0.5s;
}

.bg_green{
    background: #35E08D;
    border: 1px solid #33d386;
    color: black;
}

.input.light{
    background: rgba(228, 228, 228, 0.527);
    border: none;
    color: black;
    border-radius: 5px;
    margin: 5px;
    width: 300px;
    min-width: 200px;
    max-width: 100%;
    height: 40px;
}

.module{
    padding: 10px;
    margin: 5px;
    border-radius: 3px;
    transition: 0.4s;
}

.module.active, .module:hover{
    background: rgba(255, 255, 255, 0.123);
    transition: 0.4s;
}

.bar{
    background: #2B2B2B;
    height: 70px;
}

.hspace{
    justify-content: space-between;
}

.flex-space-evenly{
  justify-content: space-around;
}

.text-centrado{
    text-align: center;
}

.c_color{color:#33d386;}

.menu_option{
    width: 130px;
    height: 130px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    color: #707070;
    transition: 0.5s;
}

.menu_option.active, .menu_option:hover{
    border: 1px solid #004bc4;
    color: #fff;
    background: #0061FF;
    transition: 0.5s;
}

.menu_option_hover:hover .menu_option{
    border: 1px solid #004bc4;
    color: #fff;
    background: #0061FF;
    transition: 0.5s;
}

.ant-form-item{
    width: 300px;
    margin: 10px;
}

.p-float-label{
    width: 100%;
    max-width: 480px;
    margin: 20px 10px;

}

.p-fileupload{
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.p-button{
    background: #0061FF;
}

.p-button:hover{
    background: #004bc4;
}

.ov_hidden{
    overflow-x: hidden;
}

.mright15{
    margin-right: 15px;
}
.mright10{
  margin-right: 10px;
}