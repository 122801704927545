html,
body {
  height: 100%;
}

button {
  font-family: inherit;
  cursor: pointer;
  background: #1a1a1a;
  color: #f9f9f9;
  border: 0;
  border-radius: 8px;
  padding: 20px 36px;
  font-size: 16px;
}

@keyframes background-in {
  0% {
    scale: 0 0.005;
  }
  33% {
    scale: 1 0.005;
  }
  66%,
  100% {
    scale: 1 1;
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 100%;
  display: grid;
  place-items: center;
  opacity: 0;
  visibility: hidden;
  transform: scale(1, 1);
  background: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
}

body.open .modal-background {
  visibility: visible;
  opacity: 1;
  animation: background-in 1s both;
}

@keyframes modal-in {
  0%,
  66% {
    opacity: 0;
    visibility: hidden;
    translate: -50% -30%;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #efefef;
  width: 1480px;
  max-width: 90%;
  border-radius: 2px;
  translate: -50% -50%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  overflow: auto;
  z-index: 99;
}

.modal-large {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #efefef;
  width: 1480px;
  height: 720px;
  max-width: 90%;
  border-radius: 2px;
  translate: -50% -50%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  overflow: scroll;
  z-index: 99;
}

.modal_contraseña {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #f9f9f9;
  width: 1029px;
  max-width: 90%;
  border-radius: 20px;
  padding: 20px;
  translate: -50% -50%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  height: 589px;
  overflow: hidden;
  z-index: 99;
}

.window {
  background: #f9f9f9;
  padding: 30px 30px;
  width: 700px;
  max-width: 100%;
  margin: 10px;
  border-radius: 12px;
  translate: -50% -50%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

body.open .modal {
  opacity: 1;
  visibility: visible;
  animation: modal-in 1s;
}

body.open .modal-large {
  opacity: 1;
  visibility: visible;
  animation: modal-in 1s;
}



body.closed .modal {
  opacity: 0;
  visibility: hidden;
  translate: -50% -50%;
}

body.open .modal_contraseña {
  opacity: 1;
  visibility: visible;
  animation: modal-in 1s;
}

body.closed .modal_contraseña {
  opacity: 0;
  visibility: hidden;
  translate: -50% -50%;
}

h2 {
  font-weight: 400;
  font-size: 21px;
}

p {
  margin: 0;
}

.form_space {
  max-height: 100%;
  overflow-y: scroll;
  height: 265px;
}

.menu_modal {
  width: 100%;
  color: black;
  max-width: 130px;
  padding: 10px;
  background: rgb(231 231 231);
  overflow-y: scroll;
}

.nav_modal{
  width: 100%;
  max-width: 150px;
  padding: 10px;
  background: rgb(43, 43, 43);
  height: 100%;

  overflow: hidden;
}

.modal_option {
  padding: 10px;
  background: #d4d4d4;
  border-radius: 3px;
  margin: 3px;
  font-weight: 400;
  font-size: 11px;
  transition: 0.5s;
  cursor: pointer;
}

.modal_option.active,
.modal_option:hover {
  color: #ffffff;

  background: rgb(22, 22, 22);
  transition: 0.5s;
}

.close_2 {
  top: 0px;
  right: 5px;
  font-size: 40px;
  color: rgb(0, 0, 0);
}

.close {
  top: 17px;
  right: 15px;
  font-size: 30px;
  color: rgb(181, 181, 181);
}

.modal-pdf {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #efefef;
  width: 1480px;
  max-width: 90%;
  border-radius: 2px;
  translate: -50% -50%;
  opacity: 1;
  visibility: visible;
  transition: 0.3s;
  overflow: auto;
  z-index: 99;
}